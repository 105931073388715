import '../App.css';
import React from 'react';

function formatNumberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const AmortizationTable = ({ payments }) => {
    return (
        <div className="amortization-table">
        <table>
            <thead>
            <tr>
                <th>Mois</th>
                <th>Date</th>
                <th>Mensualité</th>
                <th>Intérêts</th>
                <th>Amortissement</th>
                <th>Capital restant</th>
            </tr>
            </thead>
            <tbody>
            {payments.map((payment, index) => (
                <tr key={index}>
                    <td>{payment.month}</td>
                    <td>{payment.paymentDate}</td>
                    <td>{formatNumberWithSpaces(payment.monthlyPayment?.toFixed(2))}</td>
                    <td>{formatNumberWithSpaces(payment.interestForMonth?.toFixed(2))}</td>
                    <td>{formatNumberWithSpaces(payment.principalForMonth?.toFixed(2))}</td>
                    <td>{formatNumberWithSpaces(payment.balance?.toFixed(2))}</td>
                </tr>
            ))}
            </tbody>
        </table></div>
    );
};

export default AmortizationTable;
