// src/App.js
import React, { useState, useRef } from 'react';
import LoanForm from './components/LoanForm';
import AmortizationTable from './components/AmortizationTable';
import './App.css';
import DownloadModal from './components/DownloadModal';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

function formatNumberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
function calculateAmortization(loanAmount, annualInterestRate, loanDurationMonths, startDate) {
    const monthlyInterestRate = annualInterestRate / 12 / 100;
    const tax = (10 / 100);

    const numberOfPayments = loanDurationMonths;
    const monthlyPayment =
        loanAmount *
        (monthlyInterestRate /
            (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments)));
    const monthlyTaxAmount = monthlyPayment * tax;

    const totalMonthlyPayment = monthlyPayment + monthlyTaxAmount;

    let balance = loanAmount;
    let totalInterest = 0;
    let amortizationSchedule = [];
    let paymentDate = new Date(startDate);

    for (let i = 0; i < numberOfPayments; i++) {
        const interestForMonth = balance * monthlyInterestRate;
        const principalForMonth = totalMonthlyPayment - interestForMonth;
        balance -= principalForMonth;
        totalInterest += interestForMonth;
        amortizationSchedule.push({
            month: i + 1,
            monthlyPayment: totalMonthlyPayment,
            interestForMonth: interestForMonth,
            principalForMonth: principalForMonth,
            balance: balance > 0 ? balance : 0,
        });
        amortizationSchedule[i].paymentDate = new Date(paymentDate).toISOString().split('T')[0];
        paymentDate.setMonth(paymentDate.getMonth() + 1);
    }

    return {
        monthlyPayment: totalMonthlyPayment,
        totalInterest: totalInterest,
        totalCost: loanAmount + totalInterest,
        amortizationSchedule: amortizationSchedule,
    };
}

function downloadExcel(payments) {
    // Create a new workbook and a sheet
    const wb = XLSX.utils.book_new();
    const dataForExcel = payments.map(payment => ({
        'Mois': payment.month,
        'Date': payment.paymentDate,
        'Mensualité': payment.monthlyPayment,
        'Intérêts': payment.interestForMonth,
        'Amortissement': payment.principalForMonth,
        'Capital restant': payment.balance
    }));
    const ws = XLSX.utils.json_to_sheet(dataForExcel);

    // Add Sheet to Workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Amortization');

    // Write the workbook and trigger a download
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    function s2ab(s) {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }
        return buf;
    }

    // Save to file
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    saveAs(blob, 'amortization_table.xlsx');
}


function App() {
    const [payments, setPayments] = useState([]);
    const [monthlyPayment, setMonthlyPayment] = useState(null); // Add this line
    const [tableVisible, setTableVisible] = useState(false);
    const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
    const tableContainerRef = useRef(null);
    const [viewAll] = useState(false);

    const handleScroll = () => {

        const container = tableContainerRef.current;
        const scrollBottom = container.scrollHeight - container.scrollTop - container.clientHeight;

        // Check if the user has scrolled to the bottom of the table
        if (scrollBottom < 10) { // 10 is a threshold, adjust as needed
            container.classList.add('blur');
        } else {
            container.classList.remove('blur');
        }
    };


    const calculateLoan = (loanDetails) => {
        const results = calculateAmortization(
            parseFloat(loanDetails.amount),
            parseFloat(loanDetails.interest),
            parseInt(loanDetails.months, 10),
            loanDetails.startDate
        );
        //setPayments(results.amortizationSchedule);
        setMonthlyPayment(results.monthlyPayment); // Set the monthly payment
        setPayments(results.amortizationSchedule);
        setTableVisible(true); // Show the table
    };

    const handleOpenDownloadModal = () => {
        setIsDownloadModalOpen(true);
    };

    const handleCloseDownloadModal = () => {
        setIsDownloadModalOpen(false);
    };

    const handleDownloadFormSubmit = (formData) => {
        // Here you can process the form data if needed
        // For example, you might want to validate the data or send it to a server
        console.log(formData);

        // After processing the form data, download the file
        downloadExcel(payments);
        setIsDownloadModalOpen(false); // Close the modal after downloading the file
    };

    return (
        <div className="App">
            <div className={`content ${isDownloadModalOpen ? 'blur' : ''}`}>
            <h1>Simulateur de crédit</h1>
            <LoanForm onSubmit={calculateLoan} />
            {monthlyPayment && (
                <div className="monthly-payment">
                    Mensualité: {formatNumberWithSpaces(monthlyPayment.toFixed(2))} MAD
                </div>
            )}
            {tableVisible && (
                <button onClick={handleOpenDownloadModal} className="download-button">
                    Télécharger
                </button>
            )}
                <div className={`table-container ${viewAll ? 'view-all' : ''}`} onScroll={handleScroll}>
                    <AmortizationTable payments={payments} />
                </div>
            </div>
            <DownloadModal
                isOpen={isDownloadModalOpen}
                onClose={handleCloseDownloadModal}
                onDownload={handleDownloadFormSubmit}
            />
        </div>
    );
}

export default App;
