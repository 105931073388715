// src/components/LoanForm.js
import React, { useState } from 'react';
function LoanForm({ onSubmit }) {
    const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };
    const [loanDetails, setLoanDetails] = useState({
        amount: '',
        interest: '5.65', // This will hold the interest rate value
        months: 84,
        isFixedRate: true,
        interestRateType: 'immobilier', // Added state to manage whether to use the average rate
    });
    const [useAverageRate] = useState(false);
    const handleInterestRateTypeChange = (e) => {
        const type = e.target.value;
        setLoanDetails({
            ...loanDetails,
            interestRateType: type,
            interest: type === 'immobilier' ? 5.65 : type === 'consommation' ? 8.50 : loanDetails.interest,
        });
    };

    const handleInterestChange = (e) => {
        setLoanDetails({ ...loanDetails, interest: e.target.valueAsNumber });
    };

    const handleChange = (e) => {
        const { name, valueAsNumber } = e.target;
        setLoanDetails({ ...loanDetails, [name]: valueAsNumber });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit({ ...loanDetails, amount: Number(loanDetails.amount), startDate });

    };

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit} className="loan-form">
                Montant (DHS):
                <input
                    name="amount"
                    type="number"
                    min={0}
                    max={9999999999}
                    value={loanDetails.amount}
                    onChange={handleChange}
                    placeholder="Montant du crédit en Dirhams"
                />
                <div className="interest-rate-selection radio-group">
                    <label>
                        <input
                            type="radio"
                            name="interestRateType"
                            value="custom"
                            checked={loanDetails.interestRateType === 'custom'}
                            onChange={handleInterestRateTypeChange}
                        />
                        Définir un taux
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="interestRateType"
                            value="immobilier"
                            checked={loanDetails.interestRateType === 'immobilier'}
                            onChange={handleInterestRateTypeChange}
                        />
                        Crédit Immobilier (Taux moyen: 5.65% HT)
                    </label>
                    <label>
                        <input
                            type="radio"
                            name="interestRateType"
                            value="consommation"
                            checked={loanDetails.interestRateType === 'consommation'}
                            onChange={handleInterestRateTypeChange}
                        />
                        Crédit à la consommation (Taux moyen: 8.50% HT)
                    </label>
                </div>
                <div className={`interest-rate-slider ${loanDetails.interestRateType === 'custom' ? 'visible' : ''}`}>
                    <label>
                        Taux d'intérêt (%): {loanDetails.interest}
                        <input
                            type="range"
                            min="0.1"
                            max="15"
                            step="0.1"
                            value={loanDetails.interest}
                            onChange={handleInterestChange}
                            disabled={useAverageRate}
                        /></label>
            </div>
                <div><label>Taux de TVA:</label> 10% <br/><br/> </div>
                <label>
                    Durée de crédit (Mois): {loanDetails.months}
                    <input
                        name="months"
                        type="range"
                        min="6"
                        max="300"
                        step="1"
                        value={loanDetails.months}
                        onChange={handleChange}
                    />
                </label>
                <label>
                    Date :
                    <input
                        type="date"
                        value={startDate}
                        onChange={handleStartDateChange}
                    />
                </label>
                <button type="submit">Calculer</button>
            </form>
        </div>
    );
}

export default LoanForm;
