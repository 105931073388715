// src/components/DownloadModal.js
import React from 'react';

const DownloadModal = ({ isOpen, onClose, onDownload }) => {
    if (!isOpen) return null;

    const handleSubmit = (e) => {
        e.preventDefault();
        // You might want to collect these values to use them
        // const formData = new FormData(e.target);
        // const data = Object.fromEntries(formData);
        onDownload();
    };

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal form-modal" onClick={e => e.stopPropagation()}>
                <button className="close-modal" onClick={onClose}>×</button>
                <form onSubmit={handleSubmit}>
                    <div className="form-row">
                        <label htmlFor="sex">Sexe:</label>
                        <div className="radio-group1">
                        <label>
                            <input
                                type="radio"
                                name="sex"
                                value="Femme"
                            />
                            Femme
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="sex"
                                value="Homme"
                            />
                            Homme
                        </label>
                    </div>
                    </div>

                    <div className="form-row">
                        <label htmlFor="category">Catégorie Socio-Professionnelle:</label>
                        <select name="category" required>
                            <option value="">Choisir...</option>
                            <option value="employed">Salarié</option>
                            <option value="student">Fonctionnaire</option>
                            <option value="retired">Retraité</option>
                            <option value="jobless">Femme au foyer</option>
                            <option value="student">Etudiant</option>
                            <option value="farmer">Agriculteur</option>
                            <option value="self-employed">Profession libérale</option>
                            <option value="mre">Marocain du monde</option>
                            <option value="unemployed">Auto-Entrepreneur</option>
                            <option value="tpe">TPE</option>
                            <option value="ge">Grande Entreprise</option>
                        </select>
                    </div>
                    <div className="form-row">
                        <label htmlFor="income">Revenu (MAD):</label>
                        <select name="income" required>
                            <option value="">Choisir...</option>
                            <option value="less-than-3000">Mois de 3000 MAD</option>
                            <option value="3001-6000">3000 MAD - 6000 MAD</option>
                            <option value="6001-9000">6000 MAD - 9000 MAD</option>
                            <option value="9001-12000">9000 MAD - 12000 MAD</option>
                            <option value="12001-20000">12000 MAD - 20000 MAD</option>
                            <option value="more-than-20000">Plus de 20000 MAD</option>
                        </select>
                    </div>
                    <button type="submit">Télécharger</button>
                </form>
            </div>
        </div>
    );
};
export default DownloadModal;
